import React, { useEffect, useState } from 'react';
import {
  AppstoreOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { Menu, Divider } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.scss';
import { Link } from 'react-router-dom';
import { IoPeople } from 'react-icons/io5';
import { FcLeave } from 'react-icons/fc';
import { LiaMoneyBillWaveSolid } from 'react-icons/lia';
import { MdLocalPolice, MdOutlineCoPresent, MdAssignmentAdd } from 'react-icons/md';
import { FaArchive } from 'react-icons/fa';
import { BiSolidDashboard } from 'react-icons/bi';
import { RiLockPasswordLine, RiLockPasswordFill } from 'react-icons/ri';
import MobileMenu from './MobileMenu'; // Ensure the correct path

function SideMenu() {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState('/');
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  let isAdmin = false;

  useEffect(() => {
    const pathName = location.pathname;
    setSelectedKeys(pathName);
  }, [location.pathname]);

  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      isAdmin = payload.isAdmin;
      console.log(isAdmin);
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }

  const menuItems = [
    {
      label: 'Dashboard',
      icon: <BiSolidDashboard />,
      key: '/',
    },
    {
      label: 'Attendance',
      key: '/attendance',
      icon: <MdOutlineCoPresent />,
    },
    {
      label: 'Leaves',
      key: '/leaves',
      icon: <FcLeave />,
    },
    {
      label: 'Change Password',
      key: '/change-password',
      icon: <RiLockPasswordFill />,
    },
    isAdmin && {
      label: 'Employee',
      key: '/employee',
      icon: <IoPeople />,
    },
    isAdmin && {
      label: 'Reset Password',
      key: '/employeepassword',
      icon: <RiLockPasswordLine />,
    },
    isAdmin && {
      label: 'Add Policies',
      key: '/policies',
      icon: <MdAssignmentAdd />,
    },
    {
      label: 'Company Policies',
      key: '/company-policies',
      icon: <MdLocalPolice />,
    },


    isAdmin && {
      label: 'Archived Policies',
      key: '/archivedpolicies',
      icon: <FaArchive />,
    },
  ].filter(Boolean); // Filter out null values if isAdmin is false

  return (
    <div className="flex fixed">
      <div className="hidden md:block SideMenu">
        <Menu
          mode="vertical"
          onClick={(item) => {
            navigate(item.key);
          }}
          selectedKeys={[selectedKeys]}
          items={menuItems}
          className="SideMenuVertical"
        />
        <Divider />
        <Divider />
      </div>

      <MobileMenu>
        <Menu
          mode="vertical"
          onClick={(item) => {
            navigate(item.key);
          }}
          selectedKeys={[selectedKeys]}
          items={menuItems}
        />
      </MobileMenu>
    </div>
  );
}

export default SideMenu;
