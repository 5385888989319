import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import { getEmployeeLeavesDate } from '../../API';

const BarChart = () => {
  const [leaveData, setLeaveData] = useState(null);
  const employeeId = localStorage.getItem('user')?.replace(/"/g, '');

  useEffect(() => {
    const fetchEmployeeLeavesDate = async () => {
      try {
        const response = await getEmployeeLeavesDate(employeeId);
        setLeaveData(response);
      } catch (error) {
        console.error('Error fetching employee leaves data:', error);
      }
    };

    fetchEmployeeLeavesDate();

  }, [employeeId]);

  const chartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: leaveData ? 'Employee Leaves' : 'Loading...', // Dynamically change the title based on leave data
      align: 'left'
    },
    xAxis: {
      categories: ['Annual', 'Casual', 'Medical']
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Count'
      }
    },
    legend: {
      align: 'left',
      x: 70,
      verticalAlign: 'top',
      y: 70,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true
        }
      }
    },
    series: [{
      name: 'Used',
      data: leaveData ? [leaveData.annualLeaves, leaveData.casualLeaves, leaveData.medicalLeaves] : [0, 0, 0]
    }, {
      name: 'Remaining',
      data: leaveData ? [leaveData.remainingAnnualLeaves, leaveData.remainingCasualLeaves, leaveData.remainingMedicalLeaves] : [0, 0, 0]
    }]
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
  );
};


export default React.memo(BarChart);
