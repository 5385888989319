import React, { useState, useEffect } from "react";
import { Login } from "../../../API";
import { useNavigate, Link } from "react-router-dom";
import { Form, Input, Button, Checkbox, Row, Col, Divider, Image } from "antd";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { message } from "antd";

import { useAuth } from "../../../context/AuthContext";

const Signin = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    const storedUser = localStorage.getItem("reminder");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      form.setFieldsValue(parsedUser);
      setRememberMe(true);
    }
  }, [form]);
  const onFinish = async (values) => {
    try {
      const data = await Login(values);
      if (data.status === 200) {
        message.success("Login successful");
        navigate("/");
      } else if (data.status === 401) {
        message.error(
          "Invalid credentials. Please check your employeeId and password."
        );
      }

      if (rememberMe) {
        localStorage.setItem("reminder", JSON.stringify(values));
      }
    } catch (error) {
      message.error(
        "Invalid credentials. Please check your employeeId and password."
      );
    }
  };
  return (
    <div className="h-screen flex justify-center items-center">
      <div className=" flex justify-center items-center w-full max-w-screen-lg  flex-col sm:flex-row bg-white h-screen">
        {/* Column for image (hidden on small screens) */}
        <div className="hidden sm:flex sm:w-1/2 items-center justify-center bg-white">
          <img
            className="max-w-full max-h-full  rounded-3xl shadow-1xl"
            src="/images/MetaXols-Logo.jpg"
            alt="dashboard-img"
          />
        </div>
        
        {/* Column for form (centered on all screens) */}
        <div className="w-full sm:w-1/2 flex items-center justify-center bg-white">
          <div className="p-4 sm:p-8">
            <h1 className="text-2xl mb-4">
              Login to <span className="text-blue-600">MetaXols</span>
            </h1>
            <p className="text-sm mb-6 text-center sm:text-left">Welcome back! Please enter your details.</p>
            <Form
              form={form}
              name="normal_login"
              onFinish={(values) => onFinish(values, form)}
              layout="vertical"
            >
              <Form.Item
                name="employeeId"
                label="Employee ID"
                rules={[{ required: true, message: 'Please enter your Employee ID!' }]}
              >
                <Input placeholder="Employee ID" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input.Password
                  placeholder="Password"
                  iconRender={(visible) =>
                    visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}>
                  Remember me
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
  
  };
export default Signin;
