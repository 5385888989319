import React from "react";
import { Form, Input, Button,Row, Col } from "antd";
import { UserChangePassword } from "../../../API";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
const ForgetPassword = () => {
  const navigate = useNavigate();
  const onFinish = async (values) => {
    try {
      const data = await UserChangePassword(values);
      if (data.status === 200) {
        message.success("Password changed successfully");
        navigate("/auth/signin");
      }
    } catch (error) {
      message.error("Old password is not correct");
    }
  };
  const imageStyle = {
    width: "100%",
    height: "100vh",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  };

  const imgStyle = {
    width: "100%",
    height: "100%",
  };

  return (
    <div className="h-screen flex justify-center items-center p-4 sm:p-0">
    <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-md">
      <h1 className="text-2xl font-bold mb-4">
        Reset <span className="text-blue-600">Password?</span>
      </h1>
      <p className="text-base text-gray-700 mb-4">
        Enter your email address and submit the form to send a reset password link to your email address.
      </p>
      <Form
        name="reset_password_form"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="oldPassword"
          label="Old Password"
          rules={[{ required: true, message: 'Please input your old password!' }]}
        >
          <Input type="password" placeholder="Old Password" className="bg-slate-50" />
        </Form.Item>
  
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[{ required: true, message: 'Please input your new password!' }]}
        >
          <Input type="password" placeholder="New Password" className="bg-slate-50" />
        </Form.Item>
  
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            { required: true, message: 'Please confirm your new password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords do not match!'));
              },
            }),
          ]}
        >
          <Input type="password" placeholder="Confirm Password" className="bg-slate-50" />
        </Form.Item>
  
        <Form.Item>
          <Button type="primary" htmlType="submit" className="w-full">
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  </div>
  
  ); 
};

export default ForgetPassword;
