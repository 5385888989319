import { Form, Input, Button, Row,Tag, Col, DatePicker, Select } from 'antd';
import { useNavigate ,useParams} from 'react-router-dom';
import axios from 'axios';
import { useEffect,useState } from 'react';
import { message } from 'antd';
import { ResetPassword } from '../../../API'

const { Option } = Select;

const EmployeePassword = ({state}) => {
  const [form] = Form.useForm();
  const { id } = useParams(); 
  const [copied, setCopied] = useState(false);

  const history = useNavigate();
  const onFinish = async (values) => {
    try {
      const employeeIdValue = form.getFieldValue("employeeId");
     const response= await ResetPassword(employeeIdValue);
      if (response.data.success) {
        message.success(response.data.message);
        showMessage(response.data.password);
        history('/employee');
      }else {
      message.error('Error resetting password');
        
      }
    } catch (error) {
    }
  };
  const showMessage = (text) => {
    message.success(
      <div>
        <h4>Employee Details</h4>
        <span>
          Employee Password:
          <Tag color="orange">{text}</Tag>
        </span>
        <Button
          style={{ marginLeft: 8 }}
          onClick={() => {
            copyToClipboard(`${text}`); 
          }}
        >
          Copy
        </Button>
      </div>,
      20
    );
  };
  
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    message.success('Password copied to clipboard', 2);
  };


  return (
    <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px' }}>
      <h1>Reset Employee Password Details</h1>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item label="Employee Id" name="employeeId" rules={[{ required: true, message: 'Please enter the Employee ID!' }]}>
          <Input  placeholder="Employee Id"/>
        </Form.Item>
       
    
        {/* Email, Phone, LinkedIn Section */}

        <Form.Item>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button type="primary" className='custom-primary-button w-100 h-50' htmlType="submit" style={{ width: '100%' }}>
              Save
            </Button>
          </Col>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EmployeePassword;
