import React, { createContext, useState, useContext } from 'react';

const AttendanceContext = createContext();

export const AttendanceProvider = ({ children }) => {
  const [isCheckIn, setIsCheckIn] = useState(true); // Default to check-in state

  const toggleCheckInOut = () => {
    setIsCheckIn(prevState => !prevState);
  };

  return (
    <AttendanceContext.Provider value={{ isCheckIn, toggleCheckInOut }}>
      {children}
    </AttendanceContext.Provider>
  );
};

export const useAttendance = () => useContext(AttendanceContext);
