import React, { useState } from 'react';
import { Card, Row, Col, Button, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { message } from "antd";
import PageHeader from '../../Components/common/pageHeader';
import { uploadPolicy } from '../../API/index';

const Policies = () => {
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (selectedFile) => {
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name); // Display the selected file name
    } else {
      setFile(null);
      setFileName('');
    }
  };

  const uploadFile = async () => {
    if (!title || !file) {
      console.error('Please enter a title and select a file');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('file', file);
    const response=await uploadPolicy(formData)
    console.log(response)
    if(response.data.policy._id){
      message.success('Policy uploaded successfully');
      setTitle('');
      setFile(null);
      setFileName('');
    }else{
      message.error('Failed to upload policy');

    }

  };

  const handleUpload = () => {
    uploadFile();
  };

  return (
    <>
      <div className="Matrix-page">
        <div className="header">
          <div className="left-section">
            <PageHeader title={'HRMS Policies'} />
          </div>
        </div>
        <Row gutter={16}>
          <Col span={24}>
            <div className="content-container">
              <Card title="Policies">
                <p>Upload the PDF file here.</p>
                <div    >
        <input
                    type="file"
                    style={{    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    cursor: 'pointer',
                    border: '2px dashed #cacaca',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    padding: '1.5rem',}}
                    name="file"
                    accept=".pdf, .doc, .docx"
                    onChange={(e) => handleFileChange(e.target.files[0])}
                  />
                  
                </div>
                <Input
                  placeholder="Enter policy title"
                  style={{ width: '200px', marginTop: '8px' }}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <Button
                  type="primary"
                  className='ml-2'
                  style={{ marginTop: '16px' }}
                  onClick={handleUpload}
                  disabled={!title || !file}
                >
                  Submit
                </Button>
              </Card>
              {/* Add more cards for other policies as needed */}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Policies;
