import { Space } from "antd";
// import "./App.css";
import { lazy, Suspense } from 'react';
import './styles/App.scss';
import AppHeader from "./Components/Header";
import PageContent from "./Components/PageContent";
import SideMenu from "./Components/SideMenu";
import { Outlet, useLocation } from 'react-router-dom';



function App() {

  const location = useLocation();
  const isAuthPage = location.pathname.startsWith('/auth');
  return (
    // <div className="wrapper">
    //       <ForgetPassword/>
    // </div>


    <div className="wrapper">
      {!isAuthPage && <AppHeader />}
      <div className="SideMenuAndPageContent">
      {!isAuthPage &&<SideMenu/>}
      {!isAuthPage ? <PageContent css={true} /> : <PageContent  />}
      {/* <PageContent /> */}
        {/* <PageContent></PageContent> */}
      </div>
    </div>


  );
}
export default App;
// import { Space } from "antd";
// // import "./App.css";
// import { lazy, Suspense, useState } from 'react';
// import './styles/App.scss';
// import AppHeader from "./Components/Header";
// import PageContent from "./Components/PageContent";
// import SideMenu from "./Components/SideMenu";
// import { Outlet, useLocation } from 'react-router-dom';
// const App = () => {
//   const location = useLocation();
//   const isAuthPage = location.pathname.startsWith('/auth');
//   const [showSideMenu, setShowSideMenu] = useState(true); // State to control side menu visibility

//   // Function to toggle side menu visibility
//   const toggleSideMenu = () => {
//     setShowSideMenu(!showSideMenu);
//   };

//   return (
//     <div className="">
//       {!isAuthPage && <AppHeader />}

//       {/* Main container flex layout */}
//       <div className="flex">
//         {/* Side menu (hidden on small screens) */}
//         {!isAuthPage && (
//           <div className="hidden sm:block w-64">
//             <SideMenu />
//           </div>
//         )}

//         {/* Page content (flex-grow to fill remaining space) */}
//         <div className="flex-grow">
//           {/* Hamburger menu button (visible on small screens) */}
//           <button
//             className="block sm:hidden fixed top-0 left-0 m-4 text-2xl"
//             onClick={toggleSideMenu}
//           >
//             ☰
//           </button>

//           {/* Conditional rendering of side menu based on screen size */}
//           {!isAuthPage && (
//             <div className={`sm:flex ${showSideMenu ? 'block' : 'hidden'}`}>
//               <SideMenu />
//             </div>
//           )}

//           {/* Page content (always visible) */}
//           <div className="p-4">
//             {!isAuthPage ? <PageContent css={true} /> : <PageContent />}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default App;
