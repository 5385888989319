// AuthContext.js
import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [enteredEmail, setEnteredEmail] = useState('');

  const storeEnteredEmail = (email) => {
    setEnteredEmail(email);
  };

  const [user, setUser] = useState();
  const token = localStorage.getItem('token');

  const login = (token) => {
      setUser( token );
      localStorage.setItem('token', token);
  };


  const logout = () => {
    setUser(null);
    localStorage.removeItem('token'); 
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      login(storedToken);
    }
  }, []);
  

  return (
    <AuthContext.Provider value={{ user, login, logout, enteredEmail, storeEnteredEmail }}>
      {children}
    </AuthContext.Provider>
  );
  
};

export const useAuth = () => {
  return useContext(AuthContext);
};
