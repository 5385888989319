import AppRoutes from "../AppRoutes";
import './index.scss';

function PageContent(props) {
  const pageContentClass = props.css ? 'PageContent' : '';

  return (
    <div className={`${pageContentClass}`}>
    <AppRoutes />
  </div>
  );
}
export default PageContent;
