import React, { useEffect, useState } from "react";
import { Row, Col, Card, Divider, Skeleton, Table, Button } from "antd";
import BarChart from "../../Components/MyCard/Chart";
import PageHeader from "../../Components/common/pageHeader";
import CardComponent from "../../Components/MyCard/Card";
import EmployeeCard from "../../Components/MyCard/EmployeeCard";
import moment from "moment";
import { message } from "antd";

import {
  Attendance,
  getAttendanceWeekly,
  getEmployee,
  getTodayAttendance,
} from "../../API";
import ResponsiveTable from "./ResponsivTable";
import ResponsiveUserTable from "./UserTable";
const HRMSDashboard = () => {
  const token = localStorage.getItem("token");
  let isAdmin = false;
  if (token) {
      try {
          const payload = JSON.parse(atob(token.split('.')[1]));
          isAdmin = payload.isAdmin;
      } catch (error) {
          console.error("Error decoding token:", error);
      }
  }

  const [loading, setLoading] = useState(true);
  
  const [isCheckIn, setIsCheckIn] = useState(() => {
    const savedIsCheckIn = localStorage.getItem('isCheckIn');
    return savedIsCheckIn !== null ? JSON.parse(savedIsCheckIn) : true;
  });


  useEffect(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    localStorage.setItem('isCheckIn', JSON.stringify(isCheckIn));
  }, [isCheckIn]);


  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");

  const [refreshKey, setRefreshKey] = useState(0);
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const employeeId = localStorage.getItem("user")?.replace(/"/g, "");

  const [todayAttendance, setTodayAttendance] = useState([]);

    const fetchTodayAttendance = async () => {
        const response = isAdmin && await getTodayAttendance();
        const formattedData = response?.data?.todayAttendance.map((attendance) => ({
            ...attendance,
            checkIn: moment(attendance.checkInTime),
            checkOut: moment(attendance.checkOutTime),
            employeeName: attendance.employeeName,
        }));

        setTodayAttendance(formattedData);
    };

  const checkAttendanceStatus = async () => {
      try {
          const response = await getAttendanceWeekly(employeeId);
          if (response && response.data) {
              const today = moment().format("YYYY-MM-DD");
              const todayRecord = response.data.attendanceDetails.find(record => moment(record.checkinTime).isSame(today, 'day'));
console.log("the coming todayr result",todayRecord)
              if (todayRecord) {
                  if (todayRecord.checkinTime && !todayRecord.checkoutTime) {
                      setIsCheckIn(false); // User has checked in but not checked out
                  } else if (todayRecord.checkinTime && todayRecord.checkoutTime) {
                      setIsCheckIn(true); // User has checked in and checked out, ready for new check-in
                  }
              } else {
                  setIsCheckIn(true); // No check-in record for today, ready for check-in
              }
          }
      } catch (error) {
          console.error("Error checking attendance status:", error);
      }
  };

  useEffect(() => {
      const fetchData = async () => {
          const daysToShow = 5;
          try {
              const response = !isAdmin && await getAttendanceWeekly(employeeId);
              if (response && response.data) {
                  const attendanceDetails = response.data.attendanceDetails;
                  let filteredAttendanceDetails = [];
                  if (attendanceDetails.length > daysToShow) {
                      const newRecords = attendanceDetails.slice(-1 * daysToShow);
                      filteredAttendanceDetails = newRecords.reverse();
                  } else {
                      filteredAttendanceDetails = attendanceDetails;
                  }
                  setAttendanceDetails(filteredAttendanceDetails);
              }
          } catch (error) {
              console.error("Error fetching data:", error);
          } finally {
              setLoading(false);
          }
      };

      fetchData();
      fetchTodayAttendance();
      checkAttendanceStatus();
  }, [employeeId, isAdmin, refreshKey]);

  useEffect(() => {
      setRefreshKey(prevKey => prevKey + 1);
  }, [isAdmin, employeeId]);

  const getAllEmployees = async () => {
      try {
          const response = await getEmployee();
          return response.data;
      } catch (error) {
          console.error(`Error fetching employees: ${error.message}`);
          return [];
      }
  };

  useEffect(() => {
      const fetchData = async () => {
          setLoading(true);
          setLoading(false);
      };
      fetchData();
      getAllEmployees();
  }, []);

  const calculateDuration = (checkInTime, checkOutTime) => {
      const startTime = moment(checkInTime);
      const endTime = moment(checkOutTime);

      if (endTime.isBefore(startTime)) {
          endTime.add(1, 'day');
      }
      const durationInMinutes = endTime.diff(startTime, "minutes");
      const hours = Math.floor(durationInMinutes / 60);
      const minutes = durationInMinutes % 60;
      return `${hours} hours ${minutes} minutes`;
  };

  const handleCheckInOut = async () => {
      try {
          const response = await Attendance(isCheckIn, employeeId);
          if (isCheckIn && response?.data?.checkInTime) {
            localStorage.setItem(response?.data?.checkInTime);
          setRefreshKey((prevKey) => prevKey + 1);
              setCheckInTime(response?.data?.checkInTime);
          } else {
              setCheckOutTime(response?.data?.checkOutTime);
          }
          // setIsCheckIn(!isCheckIn)
          setIsCheckIn(prevIsCheckIn => !prevIsCheckIn);
          setRefreshKey((prevKey) => prevKey + 1);
      } catch (error) {
          console.error("Error marking attendance:", error);
      }
  };

  const dataSource = attendanceDetails?.flatMap((record) => {
      const checkInTime = moment(record?.checkinTime);
      const checkOutTime = moment(record?.checkoutTime);
      const durationInMinutes = checkOutTime.diff(checkInTime, "minutes");
      const hours = Math.floor(durationInMinutes / 60);
      const minutes = durationInMinutes % 60;
      const duration = `${hours} hours ${minutes} minutes`;
      const key = `${record._id}_${record.day}`;
      const data = {
          key: key,
          employeeName: record.employeeName,
          position: record.position,
          checkIn: checkInTime.format("hh:mm A"),
          dayOfWeek: record.day,
      };
      if (record.checkoutTime) {
          data.checkOut = checkOutTime.format("hh:mm A");
          data.duration = duration;
      }
      return data;
  });

  const columns = [
      {
          title: "Day of Week",
          dataIndex: "dayOfWeek",
          key: "dayOfWeek",
      },
      {
          title: "Check-In",
          dataIndex: "checkIn",
          key: "checkIn",
      },
      {
          title: "Check-Out",
          dataIndex: "checkOut",
          key: "checkOut",
      },
      {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
      },
  ];
  const Todaycolumns = [
      {
          title: "Employee Name",
          dataIndex: "employeeName",
          key: "employeeName",
      },
      {
          title: "Day of Week",
          dataIndex: "dayOfWeek",
          key: "dayOfWeek",
      },
      {
          title: "Check-In",
          dataIndex: "checkIn",
          key: "checkIn",
          render: (text) => moment(text).format(" hh:mm A"),
      },
      {
          title: 'Check Out Time',
          dataIndex: 'checkOutTime',
          key: 'checkOutTime',
          render: (text) => text ? moment.utc(text).local().format('hh:mm A') : '-',
      },
      {
          title: "Duration",
          dataIndex: "duration",
          key: "duration",
          render: (text, record) => {
              const duration = calculateDuration(record.checkInTime, record.checkOutTime);
              return duration === "-1" ? "" : duration;
          },
      },
  ];

  return (
    <>
      <div className="Dashboard-page">
        <div className="header">
          <PageHeader title={"HRMS Dashboard"} />
        </div>
  
        <div className="flex flex-wrap -mx-4 mb-4">
          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-4">
            {isAdmin ? (
              <EmployeeCard
                title={"Welcome Admin"}
                content={"Number of Employees"}
              />
            ) : (
              <div className="bg-blue-500 p-6 rounded-lg shadow-lg w-full h-56">
                <h3 className="text-lg font-bold text-white">Mark Attendance</h3>
                <p className="text-white font-bold mt-4 mb-8">
                  Current Date: {moment().format("YYYY-MM-DD")}
                </p>
                <div className="flex justify-center">
                  <button
                    className="bg-blue-700 text-white py-2 px-4 rounded w-full sm:w-auto"
                    onClick={handleCheckInOut}
                  >
                    {isCheckIn ? "Check-in" : "Check-Out"}
                  </button>
                </div>
              </div>
            )}
          </div>
  
          <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-4">
  <div className="bg-blue-400 p-6 rounded-lg shadow-lg w-full overflow-hidden">
    <h3 className="text-lg font-bold text-black">About MetaXols</h3>
    <p className="text-white font-bold mt-4">
      <span className="text-base font-light block">Leave Policies:</span> 
      Here you can find information about the company's leave policies. 
      <br />
      <span className="text-base font-light block">Attendance Policies:</span> 
      Learn about the company's attendance policies and requirements.
    </p>
  </div>
</div>

        </div>
  
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-2/3 px-4 mb-4">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full">
              <Divider />
              {loading ? (
                <Skeleton active />
              ) : (
                <>

      {isAdmin ? (
                <Row>

        <ResponsiveTable
          dataSource={todayAttendance}
          columns={Todaycolumns}
        />
        </Row>

      ) : (
        <ResponsiveUserTable dataSource={dataSource} columns={columns} />
      )}

                </>
              )}
            </div>
          </div>
  
          {!isAdmin && (
            <div className="w-full lg:w-1/3 px-4 mb-4">
              <div className="bg-white p-6 rounded-lg shadow-lg w-full">
                <BarChartMemo />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
  
};

  const BarChartMemo = React.memo(BarChart);

export default HRMSDashboard;
