import React, { useState, useEffect } from 'react';
import { Card, Button, message } from 'antd';
import { getPolicies, archivePolicy } from '../../API/index';
import policyImage from '../../policy-3.png';

const { Meta } = Card;

const CompanyPolicy = () => {
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPolicies();
  }, []);

  const token = localStorage.getItem("token");
  let isAdmin = false;
  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      isAdmin = payload.isAdmin;
      console.log(isAdmin);
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }


  const fetchPolicies = async () => {
    try {
      const response = await getPolicies();
      // Filter out archived policies before setting the state
      const filteredPolicies = response.data.filter((policy) => !policy.isArchived);
      setPolicies(filteredPolicies);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching policies:', error);
      message.error('Failed to fetch policies');
    }
  };

  const handleCardClick = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };


  const handleArchive = async (policyId) => {
    try {
      const response = await archivePolicy(policyId);
      console.log(response);
      if (response.success) {
        message.success('Policy archived successfully');
        fetchPolicies(); // Refresh policies after archiving
      } else {
        message.error('Failed to archive policy');
      }
    } catch (error) {
      console.error('Error archiving policy:', error);
      message.error('Failed to archive policy');
    }
  };
  return (
    <div className="max-w-6xl mx-auto px-4">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-8 bg-gray-200 rounded p-4">
        Company Policies
      </h1>
      <div className="flex flex-wrap justify-center gap-4">
        {policies.length > 0 ? policies.map((policy) => (
          <Card
            key={policy._id}
            hoverable
            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4"
            cover={<img alt="Policy Image" src={policyImage} />}
          >
            <Meta
              title={policy.title}
              description={(
                <div className="flex justify-between items-center">
                  <button
                    className={isAdmin ? 
                      "bg-green-500 text-white py-2 px-4 rounded-full cursor-pointer hover:bg-green-600 transition-colors duration-300" :
                      "bg-green-500 flex items-center justify-center text-white py-2 px-4 ml-16 rounded cursor-pointer hover:bg-green-600 transition-colors duration-300"
                    }
                    onClick={(event) => handleCardClick(policy.fileUrl, event)}
                  >
                    Open
                  </button>

                  {isAdmin && (
                    <button
                      className="bg-blue-500 text-white py-2 px-4 rounded-full cursor-pointer hover:bg-blue-600 transition-colors duration-300"
                      type="danger"
                      onClick={() => handleArchive(policy._id)}
                    >
                      Archive
                    </button>
                  )}
                </div>
              )}
            />
          </Card>
        )) : (
          <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            No Policy Found
          </p>
        )}
      </div>
    </div>
  );
};

export default CompanyPolicy;
