import React, { useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Drawer, Button } from 'antd';
import 'tailwindcss/tailwind.css';

const MobileMenu = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <div className="block md:hidden mt-8 relative">
    <Button
      type="primary"
      icon={<MenuOutlined className="z-10 relative" />}
      onClick={showDrawer}
      className="z-[999] mt-[70px] ml-0 absolute p-2 border border-gray-300 bg-blue-400 rounded shadow hover:bg-gray-100"
    />
    <Drawer
      title="Menu"
      placement="left"
      onClose={closeDrawer}
      visible={visible}
      className="z-20"
    >
      {children}
    </Drawer>
  </div>
  );
};

export default MobileMenu;
