import React, { useState, useEffect } from 'react';
import { Card, Button, message, Col } from 'antd';
import moment from 'moment';

const CardComponent = ({ title, content, isCheckIn, onCheckInOut }) => {
  // Retrieve initial state from local storage or default to false if not found
  const initialCheckedInState = localStorage.getItem("isCheckedIn") === "true";
  const [isCheckedIn, setIsCheckedIn] = useState(initialCheckedInState);
  const [checkInTime, setCheckInTime] = useState(localStorage.getItem("checkInTime"));
  const [checkOutTime, setCheckOutTime] = useState(localStorage.getItem("checkOutTime"));

  useEffect(() => {
    // Update local storage whenever isCheckedIn state changes
    localStorage.setItem("isCheckedIn", isCheckedIn);
  }, [isCheckedIn]);

  useEffect(() => {
    // Determine the initial state of the button text based on local storage
    if (checkInTime && !checkOutTime) {
      setIsCheckedIn(true); // User is already checked in
    } else {
      setIsCheckedIn(false); // User is not checked in
    }
  }, [checkInTime, checkOutTime]);

  const handleCheckInOut = () => {
    const currentTime = moment().format("YYYY-MM-DD HH:mm:ss");

    if (isCheckIn) {
      if (checkInTime && checkOutTime) {
        // message.error("You have already checked in and checked out for today.");
      } else {
        setCheckInTime(currentTime);
        localStorage.setItem("checkInTime", currentTime);
        message.success("Checked in successfully");
        setIsCheckedIn(true); // Update button text to "check out"
      }
    } else {
      if (checkOutTime) {
        message.error("You have already checked out for today.");
      } else {
        setCheckOutTime(currentTime);
        localStorage.setItem("checkOutTime", currentTime);
        message.success("Checked out successfully");
        setIsCheckedIn(false); // Update button text to "check in"
      }
    }
    onCheckInOut();
  };

  return (
    <Card
      title={<strong>{title}</strong>}
      size="small"
      style={{
        width: 400,
        height: 220,
        backgroundColor: "#00BFFF",
        boxShadow: "0 4px 8px rgba(230, 157, 144)",
      }}
    >
      <p style={{ marginBottom: 8, fontWeight: "bold", color: "gray", fontSize: 18 }}>{content}</p>
      <p style={{ marginBottom: 8, fontSize: 16, fontWeight: "bold" }}>Current Date: {moment().format("YYYY-MM-DD")}</p>
      <Col offset={8}>
        <Button
          type="primary"
          style={{ marginTop: 17, marginLeft: -70 }}
          className="w-100 h-10 mt-4"
          onClick={handleCheckInOut}
        >
          {isCheckedIn ? "Check-out" : "Check-in"} {/* Updated button text based on isCheckedIn state */}
        </Button>
      </Col>
    </Card>
  );
};

// import React, { useState, useEffect } from 'react';
// import { Card, Button, message } from 'antd';
// import moment from 'moment';

// const CardComponent = ({ title, content }) => {
//   // Retrieve check-in status from localStorage, defaulting to true if not found
//   const [isCheckIn, setIsCheckIn] = useState(() => {
//     const storedStatus = localStorage.getItem('isCheckIn');
//     return storedStatus !== null ? JSON.parse(storedStatus) : true;
//   });

//   const handleCheckInOut = () => {
//     const newCheckInStatus = !isCheckIn;
//     setIsCheckIn(newCheckInStatus);
//     localStorage.setItem('isCheckIn', JSON.stringify(newCheckInStatus));
//     message.success(newCheckInStatus ? 'Checked Out' : 'Checked In');
//   };

//   return (
//     <Card
//       title={<strong>{title}</strong>}
//       size="small"
//       style={{
//         width: 400,
//         height: 220,
//         backgroundColor: "#00BFFF",
//         boxShadow: "0 4px 8px rgba(230, 157, 144)",
//       }}
//     >
//       <p style={{ marginBottom: 8, fontWeight: "bold", color: "gray", fontSize: 18 }}>{content}</p>
//       <p style={{ marginBottom: 8, fontSize: 16, fontWeight: "bold" }}>Current Date: {moment().format("YYYY-MM-DD")}</p>
      
//       <Button onClick={handleCheckInOut} type="primary" danger={!isCheckIn}>
//         {isCheckIn ? 'Check In' : 'Check Out'}
//       </Button>
//     </Card>
//   );
// };

export default CardComponent;
