import React, { useState, useEffect } from 'react';
import { Col, Row, Input, Modal, Table, Form, Select, Pagination, DatePicker, TimePicker, Button } from 'antd';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { getAttendanceMonthly, updateAttendance, getAllAttendance, getSingleEmployee } from "../../API/index";

const { Option } = Select;

const staticMonths = [
  '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
];

function Attendance() {
  const token = localStorage.getItem("token");
  let isAdmin = false;
  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      isAdmin = payload.isAdmin;
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  const [form] = useForm();
  const [filteredData, setFilteredData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().format('MMM'));
  const [name, setName] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [pageSize, setPageSize] = useState(20); // Added pageSize state

  const handleMonthChange = (value) => {
    const formattedMonth = moment(value, 'MM').format('MMM');
    setSelectedMonth(formattedMonth);
    setCurrentPage(1);
  };

  const getoneEmployee = async () => {
    const data = await getSingleEmployee(localStorage.getItem('user')?.replace(/"/g, ''));
    setName(data?.data?.data?.name);
  };

  const fetchData = async (search = '', page = 1) => {
    try {
      const response = isAdmin
        ? await getAllAttendance(search, page, pageSize, false, selectedMonth)
        : await getAttendanceMonthly(localStorage.getItem('user')?.replace(/"/g, ''), selectedMonth, page, pageSize);
      setTotalRecords(response?.total || 0);
      setApiData(response?.monthlyData || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData('', currentPage);
    getoneEmployee();
  }, [selectedMonth, isAdmin, refreshKey, currentPage]);

  const filterDataByMonth = () => {
    if (selectedMonth === null) {
      setFilteredData(apiData || []);
    } else {
      const newData = (apiData || []).filter((attendance) =>
        moment(attendance?.checkInTime).format('MMM') === selectedMonth
      );
      setFilteredData(newData);
    }
  };

  useEffect(() => {
    filterDataByMonth();
  }, [apiData, selectedMonth, refreshKey]);

  const tableColumns = [
    isAdmin ? {
      title: 'Employee Name',
      dataIndex: 'employeeId',
      key: 'employeeId',
      render: (text, record) => <span>{isAdmin ? record?.employeeId?.name : name}</span>,
    } : {},
    {
      title: 'Check in Time',
      dataIndex: 'checkInTime',
      key: 'checkInTime',
      render: (text) => moment(text).format('hh:mm A'),
    },
    {
      title: 'Check Out Time',
      dataIndex: 'checkOutTime',
      key: 'checkOutTime',
      render: (text) => text ? moment.utc(text).local().format('hh:mm A') : '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Date',
      dataIndex: 'updatedAt',
      key: 'date',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    isAdmin ? {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
        </>
      ),
    } : {
      title: '',
      key: 'action',
      render: () => null,
    },
  ];

  const handleSearch = (value) => {
    setCurrentPage(1);
    fetchData(value.toLowerCase(), 1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData('', page);
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
    if (record) {
      form.setFieldsValue({
        checkinTime: moment(record?.checkInTime),
        checkoutTime: moment(record?.checkOutTime),
        date: moment(record?.createdAt),
      });
    }
  };

  const renderMonthOption = (month) => {
    return month === 'All'
      ? moment().format('MMM')
      : moment(`${moment().year()}-${month}`, 'YYYY-MM').format('MMM');
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      await updateAttendanceInDB(values);
      setRefreshKey((prevKey) => prevKey + 1);
      setSelectedRecord(null);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error updating attendance:', error);
    }
  };

  const updateAttendanceInDB = async (updatedValues) => {
    try {
      const { checkinTime, checkoutTime, date } = updatedValues;
      const updatedRecord = {
        updatedAt: date,
        checkinTime,
        checkoutTime,
        editBy: 'Admin',
      };
      const response = await updateAttendance(selectedRecord.employeeId._id, updatedRecord);
      if (!response.data.success) {
        console.error('Failed to update attendance:', response.data.message);
      }
    } catch (error) {
      console.error('Error updating attendance:', error);
    }
  };

  return (
    <>
      <div className="Matrix-page">
        <div className="header">
          <div className="left-section"></div>
          <Row gutter={16} align="middle"></Row>
        </div>

        <Row gutter={16}>
          <Col span={24}>
            <div className="table-container">
              <Table
                dataSource={filteredData}
                columns={tableColumns}
                pagination={false}
                bordered
                title={() => (
                  <div className="flex flex-col z-in sm:flex-row sm:justify-between items-center mb-4">
                    <div className="mb-4 sm:mb-0">
                      <h2 className="text-xl font-bold">Attendance History</h2>
                    </div>
                    <div className="flex flex-col sm:flex-row sm:items-center">
                      <div className="flex items-center mb-4 sm:mb-0 sm:mr-4">
                        <span className="mr-2">Filter by Month:</span>
                        <Select
                          defaultValue={moment().format('MM')}
                          className="w-32"
                          onChange={handleMonthChange}
                        >
                          {staticMonths.map(month => (
                            <Select.Option key={month} value={month}>
                              {renderMonthOption(month)}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                      {isAdmin && (
                        <Input.Search
                          placeholder="Search by name"
                          onSearch={handleSearch}
                          onChange={(e) => handleSearch(e.target.value)}
                          className="w-full sm:w-64"
                        />
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
          </Col>
        </Row>
     <Pagination
      current={currentPage}
      total={totalRecords}
      pageSize={20}
      onChange={handlePageChange}
      showLessItems={false} // Set showLessItems to false
      showSizeChanger={false} // Set showSizeChanger to false
      style={{ marginTop: '20px', textAlign: 'center' }}
    />

      </div>
      <Modal
        title="Edit Record"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        onOk={handleSave}
      >
        {/* Populate the modal fields with the selectedRecord data */}
        <Form form={form}>
    <Form.Item label="Check-in Time" name="checkinTime">
      <TimePicker format="HH:mm" />
    </Form.Item>
    <Form.Item label="Check-out Time" name="checkoutTime">
      <TimePicker format="HH:mm" />
    </Form.Item>
    <Form.Item label="Date" name="date">
      <DatePicker format="YYYY-MM-DD" />
    </Form.Item>
  </Form>
      </Modal>
    </>
  );
}

export default Attendance;