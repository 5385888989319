import React, { useState } from "react";
import { Row, Col, Card, Form, Input, Button, Table, Space, Modal } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import GenericTable from "../../Components/Tables";
import PageHeader from "../../Components/common/pageHeader";


const FinanceComponent = () => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [data, setData] = useState([
    {
      key: 1,
      transactionDate: "2024-01-25",
      description: "Groceries",
      amount: 50.0,
    },
    {
      key: 2,
      transactionDate: "2024-01-24",
      description: "Dinner",
      amount: 30.0,
    },
    {
        key: 2,
        transactionDate: "2024-01-24",
        description: "Dinner",
        amount: 30.0,
      },
      {
        key: 2,
        transactionDate: "2024-01-24",
        description: "Dinner",
        amount: 30.0,
      },
    // Add more static data as needed
  ]);

  const columns = [
    {
      title: "Transaction Date",
      dataIndex: "transactionDate",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record.key)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.key)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const handleAdd = () => {
    setIsModalVisible(true);
  };

  const handleEdit = (key) => {
    setEditingKey(key);
    const selectedTransaction = data.find((item) => item.key === key);
    form.setFieldsValue(selectedTransaction);
    setIsModalVisible(true);
  };
  

  const handleDelete = (key) => {
    const updatedData = data.filter((item) => item.key !== key);
    setData(updatedData);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updatedData = [...data];

      if (editingKey) {
        const index = updatedData.findIndex((item) => item.key === editingKey);
        updatedData[index] = { ...values, key: editingKey };
      } else {
        updatedData.push({ ...values, key: Date.now() });
      }

      setData(updatedData);
      setIsModalVisible(false);
      setEditingKey("");
      form.resetFields();
    } catch (error) {
      console.log("Validation failed:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingKey("");
    form.resetFields();
  };
  const tableHeader = 'Finance History';
  const handleRowClick = (record) => {
    if (record && record.id) {
    } else {
      console.error("Record or record.id is undefined");
    }
  };
  const isEditing = (record) => record.key === editingKey;

  return (
    <>
      <div className="Matrix-page">
      <PageHeader title={'Finance History'} />

        
        <Row justify="end" style={{ marginBottom: 16 }}>

          <Col>
            <Button icon={<PlusOutlined />} onClick={handleAdd} className='custom-primary-button w-100 h-50'>Add Transaction</Button>
          </Col>

        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div className="table-container">
        <PageHeader title={'Finance '} />
        <GenericTable header={tableHeader} columns={columns} data={data} onRowClick={handleRowClick} />
        </div>
          </Col>
        </Row>
        <Modal
          title={editingKey ? "Edit Transaction" : "Add Transaction"}
          visible={isModalVisible}
          onOk={handleSave}
          onCancel={handleCancel}
          destroyOnClose
        >
          <Form form={form} layout="vertical">
            <Form.Item name="transactionDate" label="Transaction Date" rules={[{ required: true, message: "Please enter transaction date" }]}>
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description" rules={[{ required: true, message: "Please enter description" }]}>
              <Input />
            </Form.Item>
            <Form.Item name="amount" label="Amount" rules={[{ required: true, message: "Please enter amount" }]}>
              <Input type="number" />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
  
};

export default FinanceComponent;
