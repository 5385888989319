import React from 'react';
import moment from 'moment';

const ResponsiveUserTable = ({ dataSource, handleCheckInOut }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-">
        <thead className="bg-black">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Day of Week</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Check-In</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Check-Out</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Duration</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {dataSource.map((record) => (
            <tr key={record.key}>
              <td className="px-6 py-4 whitespace-nowrap">{record.dayOfWeek}</td>
              <td className="px-6 py-4 whitespace-nowrap">{record.checkIn}</td>
              <td className="px-6 py-4 whitespace-nowrap">{record.checkOut ? record.checkOut : <button onClick={() => handleCheckInOut()}></button>}</td>
              <td className="px-6 py-4 whitespace-nowrap">{record.duration}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResponsiveUserTable;
