import  {useState} from 'react';
import { Form, Input, Button, Row, Col, DatePicker, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

import { CreateUser } from '../../API';
import {message,Tag} from 'antd';
const { Option } = Select;
const EmployeeDetail = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [copied, setCopied] = useState(false);
  const [employeeId, setId] = useState('');

  const onFinish = async (values) => {
    const data= await CreateUser(values)
 
    if (data.status === 200) {
      const newPassword = data.data.data.password;
      const employeeId = data.data.data.employeeId;
      setId(employeeId);
      showMessage(newPassword,employeeId);
      message.success('Employee created successfully');
      history('/employee');
    }
  
  };
  const showMessage = (text, employeeId) => {
    message.success(
      <div>
        <h4>Employee Details</h4>
        <span>
          Employee Password:
          <Tag color="orange">{text}</Tag>
        </span>
        <span>
          Employee Id:
          <Tag color="orange">{employeeId}</Tag>
        </span>
  
        <Button
          style={{ marginLeft: 8 }}
          onClick={() => {
            copyToClipboard(`${text} ${employeeId}`); 
          }}
        >
          Copy
        </Button>
      </div>,
      20
    );
  };
  
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    message.success('Password copied to clipboard', 2);
  };


  return (
<div class="max-w-xl mx-auto p-4">
  <h1 class="text-2xl font-bold mb-4">Employee Details</h1>
  <Form form={form} onFinish={onFinish} layout="vertical">
    <Form.Item
      label="Full Name"
      name="name"
      rules={[{ required: true, message: 'Please enter the full name!' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Father Name"
      name="fatherName"
      rules={[{ required: true, message: 'Please enter the father\'s name!' }]}
    >
      <Input />
    </Form.Item>
    <Row gutter={16}>
      <Col span={12} class="sm:span-full">  <Form.Item label="Birth Date" name="dateOfBirth">
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
      </Col>
    </Row>
    {/* Current Address Section */}
    <Form.Item
      label="Current Address"
      name={['currentAddress', 'streetAddress']}
      rules={[{ required: true, message: 'Please enter the street address!' }]}
    >
      <Input placeholder="Street Address" />
    </Form.Item>
    <Row gutter={16}>
      <Col span={12} class="md:span-6 lg:span-4">  <Form.Item name={['currentAddress', 'city']} rules={[{ required: true, message: 'Please enter the city!' }]}>
          <Input placeholder="City" />
        </Form.Item>
      </Col>
      <Col span={12} class="md:span-6 lg:span-4">  <Form.Item name={['currentAddress', 'state']} rules={[{ required: true, message: 'Please enter the state/province!' }]}>
          <Input placeholder="State / Province" />
        </Form.Item>
      </Col>
      <Col span={12} class="md:span-full lg:span-4">  <Form.Item name={['currentAddress', 'postalCode']} rules={[{ required: true, message: 'Please enter the postal/zip code!' }]}>
          <Input placeholder="Postal / Zip Code" />
        </Form.Item>
      </Col>
    </Row>
    {/* Email, Phone, LinkedIn Section */}
    <Form.Item
      label="Email Address"
      name="email"
      rules={[{ type: 'email', message: 'Please enter a valid email address!' }]}
    >
      <Input placeholder="example@example.com" />
    </Form.Item>
    <Form.Item label="Phone Number" name="phone">
      <Input placeholder="(000) 000-0000" />
    </Form.Item>
    <Form.Item label="LinkedIn" name="linkedIn">
      <Input />
    </Form.Item>
    {/* Position Applied Section */}
    <Form.Item label="Position Applied" name="position">
      <Select placeholder="Please Select">
        <Option value="position1">Position 1</Option>
        <Option value="position2">Position 2</Option>
      </Select>
    </Form.Item>
    {/* Submit Button */}
    <Form.Item>
      <Button type="primary" htmlType="submit" class="w-full h-10">
        Add Employee
      </Button>
    </Form.Item>
  </Form>
</div>

  );
};

export default EmployeeDetail;
