import {  Route, Routes } from "react-router-dom";
import Dashboard from "../../Pages/Dashbaord";
import Attendance from "../../Pages/Attendance";
import Signin from "../../Pages/auth/Signin";
import  Leaves  from "../../Pages/Leaves";
import ForgetPassword from "../../Pages/auth/ForgetPassword";
import { useAuth } from "../../context/AuthContext";
import Policies from "../../Pages/Policies";
import ArchivedPolicy from "../../Pages/ArchivedPolicy";
import { useState } from "react";
import Employee from "../../Pages/Employee";
import FinanceComponent from "../../Pages/Finance/Finance";
import EmployeeDetail from "../../Pages/Employee/EmployeeForm";
import EmployeeDetails from "../../Pages/Employee/EmployeeDetails";
import {useNavigate} from "react-router-dom";
import { useEffect } from "react";
import { Spin } from 'antd';
// import jwtDecode from "jwt-decode";
import EmployeePassword from "../../Pages/auth/Admin/EmployeePassword";
import CompanyPolicy from "../../Pages/CompanyPolicy";
const PrivateRoute = ({ component: Component, ...props }) => {
  const navigate=useNavigate()
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const token=localStorage.getItem("token")
  // if (token) {
  //   const decodedToken = jwtDecode(token);
  //   const currentTime = Date.now() / 1000; // Convert to seconds
  //   if (decodedToken.exp < currentTime) {
  //     navigate("/auth/signin");
  //     alert("Your session has expired. Please log in again.");
  //   }
  // }
  if (!token) {
    navigate("/auth/signin");
  } 
  useEffect(() => {
    const fetchData = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setLoading(false);
    };
    if (token) {
      fetchData();
    } else {
      navigate("/auth/signin");
    }
  }, [token, navigate]);
  if (token && !loading) {
    return <Component {...props} />;
  }
  return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}> <Spin size="large" /></div>;
};


  function AppRoutes() {
    const token = localStorage.getItem("token");
    let isAdmin = false;
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        isAdmin = payload.isAdmin;
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
    return (
      <Routes>
        <Route path="/" element={<PrivateRoute component={Dashboard} />} />
        <Route path="/attendance" element={<PrivateRoute component={Attendance} />} />
        <Route path="/leaves" element={<PrivateRoute component={Leaves} />} />
        <Route path="/company-policies" element={<PrivateRoute component={CompanyPolicy} />} />
 
          
        <Route path="/employeedetails/:id?" element={<PrivateRoute component={EmployeeDetails} />} />
  
        {isAdmin && (
          <>
            <Route path="/employeform" element={<PrivateRoute component={EmployeeDetail} />} />
            <Route path="/employee" element={<PrivateRoute component={Employee} />} />
            <Route path="/employeepassword" element={<PrivateRoute component={EmployeePassword} />} />
            <Route path="/finance" element={<PrivateRoute component={FinanceComponent} />} />
            <Route path="/policies" element={<PrivateRoute component={Policies} />} />
            <Route path="/archivedpolicies" element={<PrivateRoute component={ArchivedPolicy} />} />
          </>
        )}
      
        <Route path="/auth/signin" element={<Signin />} />
        {/* <Route path="/auth/signup" element={<Signup />} /> */}
        <Route path="/change-password" element={<PrivateRoute component={ForgetPassword} />} />
      </Routes>
    );
  }
  
export default AppRoutes;