import React, { useEffect, useState } from "react";
import { Card, Col, Button } from "antd";
import moment from "moment";
import { getEmployee } from "../../API";
const EmployeeCard = ({ title, content }) => {
  const [employee, setEmployee] = useState();
  const handleCheckInOut = () => {

  };
 useEffect(() => {
  const fetchData = async () => {
    try {
      const employees = await getEmployee();
      const numberOfEmployees = employees?.data?.total;
      setEmployee(numberOfEmployees);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  fetchData()
 })

  return (
<div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div className="bg-blue-400 p-6 rounded-lg shadow-lg w-full overflow-hidden">
      <h3 className="text-lg font-bold text-black">{title}</h3>
      <p className="text-white font-bold mt-4">
        <h2 className="text-base font-light block text-center">Number of Employee </h2> 
        <br />
        <h1 className="font-bold text-3xl text-center">{employee}</h1> 
      </p>
    </div>
  </div>
);
};

export default EmployeeCard;
