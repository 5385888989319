import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import {Pagination} from 'antd';
import PageHeader from '../../Components/common/pageHeader';
import PackageCard from '../../Components/Cards/statsCard';
import axios from 'axios'
import { getEmployee } from '../../API'
// import EmployeeDetail from './EmployeeDetails';
const Employee = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);


  const navigate = useNavigate();
  const fetchData = async (search = '', page = 1, pageSize = 10) => {
    try {
      const response = await getEmployee(search, page, pageSize, false);
      setEmployeeData(response?.data?.data);
      setTotalRecords(response?.data?.total);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);;

  const handleSearch = (value) => {
    setCurrentPage(1);
    fetchData(value.toLowerCase());
  };
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData('', page);
  };




  const navigateToEmployeeDetail = () => {
    navigate('/employeform');
  };

  return (
    <>
    <div className="w-full flex items-center justify-between">
    <div className="w-full md:w-1/2">
      <h1 className="text-3xl font-bold ml-3 fixed">Employees</h1>
    </div>
    <div className="w-full flex items-center justify-between">
    <div className="w-full md:w-1/2">
      <Input.Search

        placeholder="Search by name"
        onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        className="w-full fixed md:w-96"
      />
      </div>
      </div>
    </div>
  
  <div className=" w-full flex justify-end  mt-6 sm:mt-0 ">
    <Button
      className="custom-primary-button fixed mt-3 sm:mt-0"
      onClick={navigateToEmployeeDetail}
    >
      Add Employee
    </Button>
  </div>
  

          
        
        <div className="mt-24 p-4 z-10">
          <Row gutter={[16, 16]}>
            {employeeData.map((employee, index) => (
              <Col span={24} md={12} lg={8} key={index} className="mb-4">
                <PackageCard
                  name={employee?.name}
                  id={employee?.employeeId}
                  position={employee?.position}
                  address={employee?.currentAddress?.city}
                  number={employee?.phone}
                  active={employee?.active}
                  const employee={setEmployeeData}
                />
              </Col>
            ))}
          </Row>
          <Pagination
            current={currentPage}
            total={totalRecords}
            pageSize={10}
            onChange={handlePageChange}
            className="mt-4 text-center"
          />
      </div>
    </>
  );
};

export default Employee;