// Header.js

import  {useEffect, useState} from 'react';
import { Layout, Dropdown, Menu, Button, Avatar,Image ,Space} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.scss'; // Import the custom styles
import {useNavigate} from 'react-router-dom'
import { getSingleEmployee } from '../../API';
const { Header } = Layout;
const items = [
  {
    label: 'Logout',
    key: 'logout',
  },
  {
    label: 'Profile Settings',
    key: 'profile',
  },
];



const AppHeader = () => {
  const [name ,setName]=useState('');
  const [postion ,setPostion]=useState('');
  const id = localStorage?.getItem('user');
  const cleanedId = id?.replace(/"/g, '');

  const fetchData = async () => {
  const data = await getSingleEmployee(cleanedId);
  setName(data?.data?.data?.name)
  setPostion(data?.data?.data?.position)

    
  }
  useEffect(() => {

    fetchData();
  })
  const navigate = useNavigate();
  const handleMenuClick = ({ key }) => {
    if (key === 'logout') {   
      const token=localStorage.removeItem('token');
      const buton=localStorage.removeItem('buttonText');
      const user=localStorage.removeItem('user');
      const butochkinn=localStorage.removeItem('checkInTime');
      const chkout=localStorage.removeItem('checkOutTime');

      navigate('auth/signin');
    } else if (key === 'profile') {
    }
    else if (key === 'password') {
      navigate('auth/change-password');
    }
  };
  

  
const menu = (
  <Menu onClick={handleMenuClick}>
    {items.map((item) => {
      if (item.type === 'divider') {
        return <Menu.Divider key={item.key} />;
      }
      return <Menu.Item key={item.key}>{item.label}</Menu.Item>;
    })}
  </Menu>
)
  return (
    <Header className="ant-header">
      <div className="logo hidden sm:block">
    <img src='images/MetaXols-Logo-Final-e1699461361606.png' alt='logo' className="logo-img"/>
</div>

        <div className='user-profile'>
          <div class="profileImage"><img src="images/avatar-659651_640.png" className='avatar'/></div>
          <div class="nameDropdown">
            <p className='name'>{name}</p>
            <Dropdown overlay={menu} trigger={['click']}>
        <a onClick={(e) => e.preventDefault()} className='profiledropdown'>
          <Space>
            {postion}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
          </div>
      </div>
    </Header>
  );
};

export default AppHeader;
