import React, { useState, useEffect } from 'react';
import { Card, Button, message } from 'antd';
import { getPolicies, archivePolicy } from '../../API/index';
import policyImage from '../../policy-3.png';

const { Meta } = Card;

const ArchivedPolicy = () => {
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPolicies();
  }, []);

  const token = localStorage.getItem("token");
  let isAdmin = false;
  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      isAdmin = payload.isAdmin;
      console.log(isAdmin);
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }


  const fetchPolicies = async () => {
    try {
      const response = await getPolicies();
      // Filter out archived policies before setting the state
      const filteredPolicies = response.data.filter((policy) => policy.isArchived===true);
      setPolicies(filteredPolicies);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching policies:', error);
      message.error('Failed to fetch policies');
    }
  };

  const handleCardClick = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };



  return (
    <>
      <div className="max-w-6xl mx-auto px-4">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-8 bg-gray-200  rounded p-4">
        Archived Policies
      </h1>
        <div className="flex flex-wrap gap-4 ">
          {policies.map((policy) => (
            <Card
              key={policy._id}
              hoverable
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mb-4"
              cover={<img alt="Policy Image" src={policyImage} />}
            >
              <Meta
                title={policy.title}
                description={(
                  <div className="flex items-center justify-center">
                  <button
  className=
    "bg-green-500 flex items-center justify-center text-white py-2 px-4 rounded cursor-pointer hover:bg-green-600 transition-colors duration-300"
  
  onClick={(event) => handleCardClick(policy.fileUrl, event)}
>
  Open
</button>

                  </div>
                )}
              />
            </Card>
          ))}
        </div>
      </div>
    </>
  );
};

export default ArchivedPolicy;
