import axios from "axios";

let token = localStorage.getItem("token");

const base_url = process.env.REACT_APP_BASE_URL;
// Login api
console.log("the base url", base_url);

export const Login = async (values) => {
  try {
    const response = await axios.post(`${base_url}/user/login`, {
      employeeId: values.employeeId,
      password: values.password,
    });

    if (response.status === 200) {
      const token = response.data.data.accessToken;
      const user = response.data.data.EmployeeId;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      // localStorage.setItem("admin", JSON.stringify(admin));
      return response;
    } else {
      console.error("Login failed:", response.data);
    }
  } catch (error) {
    console.error("An error occurred during login:", error);
  }
};

export const getAttendanceMonthly = async (
  cleanedId,
  selectedMonth,
  page = 1,
  pageSize = 500
) => {
  try {
    console.log("Requested Month:", selectedMonth);

    const response = await axios.get(`${base_url}/getAttendanceMonthly/${cleanedId}`, {
      params: {
        month: selectedMonth,
        page: page,
        pageSize: pageSize,
      }
    });

    const data = response.data;
    console.log("Received Attendance Data:", data);
    return data;
  } catch (error) {
    console.error("An error occurred during fetching attendance:", error);
    throw error; // Ensure errors are thrown for handling in the calling function
  }
};


const getMonthNumber = (monthName) => {
  const monthIndex = new Date(`${monthName} 1, 2022`).getMonth() + 1;
  return monthIndex; 
};
export const getAllAttendance = async (search = '', page = 1, pageSize = 20, getAll = false, month) => {
  try {
    const token = localStorage.getItem('token');
    const parsedMonth = !isNaN(month) ? parseInt(month, 10) : getMonthNumber(month);
    const response = await axios.get(`${base_url}/getAllAttendance`, {
      params: {
        search,
        page,
        pageSize,
        getAll,
        month:parsedMonth
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = response.data;
    console.log('Attendance data:', data);

    return data; 
  } catch (error) {
    console.error('An error occurred during fetching attendance:', error);
    throw error; 
  }
};
export const getAttendanceWeekly = async (employeeId) => {
  try {
    const response = await axios.get(
      `${base_url}/getAttendanceWeekly/${employeeId}`
    );

    return response;
  } catch (error) {
    console.error("An error occurred during login:", error);
  }
};
export const getEmployeeAttendance = async (employeeId) => {
  try {
    const response = await axios.get(
      `${base_url}/getEmployeeAttendance/${employeeId}`
    );

    return response;
  } catch (error) {
    console.error("An error occurred during login:", error);
  }
};

export const CreateUser = async (values) => {
  try {
    const response = await axios.post(`${base_url}/user/create`, values);
    return response;
  } catch (error) {
    console.error("Error creating user:", error);
  }
};

export const UserChangePassword = async (values) => {
let token = localStorage.getItem("token");

  try {
    console.log(values)
    const response = await axios.post(
      `${base_url}/user/changePassword`,
      {
        password: values.oldPassword,
        newPassword: values.newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error during password change:", error.response.data);
  }
};

export const ResetPassword = async (employeeIdValue) => {
let token = localStorage.getItem("token");

  try {
    const requestBody = {
      employeeId: employeeIdValue,
    };
    const response = await axios.post(
      `${base_url}/user/resetPassword`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error resetting password:", error);
  }
};

export const AcceptLeave = async (employeeIdValue, leaveId) => {
  try {
    const requestBody = {
      employeeId: employeeIdValue,
    };
    const response = await axios.put(
      `${base_url}/leave-requests/${leaveId}/accept`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error resetting password:", error);
  }
};
export const RejectLeave = async (employeeIdValue, leaveId) => {
  try {
    const requestBody = {
      employeeId: employeeIdValue,
    };
    const response = await axios.put(
      `${base_url}/leave-requests/${leaveId}/reject`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error resetting password:", error);
  }
};

export const Attendance = async (isCheckIn, employeeId) => {
  try {
    const response = await axios.post(`${base_url}/attendance`, {
      employeeId: employeeId?.replace(/"/g, ""),
      type: isCheckIn ? "checkIn" : "checkOut",
    });
    return response;
  } catch (error) {}
};

export const getEmployee = async (
  search = "",
  page = 1,
  pageSize = 500,
  getAll = false
) => {
  try {
    const response = await axios.get(`${base_url}/employee`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        search: search,
        getAll: getAll,
        page: page,
        pageSize: pageSize,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching employee data:", error);
    throw error;
  }
};


export const uploadPolicy = async (formData)=>{
  try {
    const token = localStorage.getItem('token'); // Get token from localStorage
    const response = await axios.post(`${base_url}/uploadPolicy`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    console.error('Error uploading policy:', error);
  }

}


export const getPolicies = async () => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`${base_url}/policies`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {}
};





export const archivePolicy = async (policyId) => {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.put(`${base_url}/policies/archive/${policyId}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error archiving policy:', error);
    throw error; 
  }
}

export const getTodayAttendance = async () => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`${base_url}/getTodayAttendance`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {}
};
export const updateAttendance = async (id, updateRecord) => {
  try {
    const response = await axios.put(`${base_url}/updateAttendance/${id}`, updateRecord, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error('Error updating attendance:', error);
  }
};

export const postLeaveRequest = async (values) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(`${base_url}/leaveRequest`, values,{
      headers:{
        Authorization:`Bearer ${token}`
      }

    });
    return response.data;
  } catch (error) {
    console.error('Error submitting leave request:', error);
    throw error; // Re-throw the error to propagate it to the caller if needed
  }
};


export const getEmployeeLeave = async (employeeId) => {
  try {
    const response = await axios.get(
      `${base_url}/leave-requests/${employeeId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {}
};

export const getAllEmployeeLeaves = async (
  search = "",
  page = 1,
  pageSize = 500,
  getAll = false
) => {
  try {
    const response = await axios.get(`${base_url}/leaveRequest/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        search: search,
        getAll: getAll,
        page: page,
        pageSize: pageSize,
      },
      
    });
    return response;
  } catch (error) {}
};
export const getEmployeeDetails = async (employeeId) => {
  try {
    const response = await axios.get(`${base_url}/employees/${employeeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {}
};

export const updateEmployeeDetails = async (employeeId) => {
  try {
    const response = await axios.put(`${base_url}/employees/${employeeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {}
};
export const getSingleEmployee = async (id) => {
  try {
    const response = await axios.get(`${base_url}/singleemployee/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {}
};

export const getEmployeeLeavesDate = async (id) => {
  try {
    const response = await axios.get(`${base_url}/leaves/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {}
};

