import React from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation

const GenericTable = ({ header, columns, data, redirectPath ,onRowClick ,tableStyle}) => {
    const navigate = useNavigate();

  const handleRowClick = (record) => {
    navigate(`${redirectPath}/${record.id}`); // Update this with your actual data property or logic
  };

  // Add the row click handler to the table
  const rowProps = {
    onClick: (event, record) => {
      handleRowClick(record);
    },
  };

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      style={{color:'red'}}
      onRow={(record) => ({
        onClick: () => onRowClick(record),
      })}
    />
  );
};

export default GenericTable;
